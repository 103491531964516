<template>
  <v-data-table
    :headers="computedHeaders"
    :items="items.existing"
    :value="value.existing"
    :search="search"
    @input="onInput($event)"
    :loading="isBusy"
  >
    <template v-if="!hideTitle" v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Promotion Products</v-toolbar-title>
        <v-spacer />
        <v-form @submit.stop.prevent="onSearch">
          <v-text-field
            class="mr-4"
            v-model="searchField"
            :label="$i18n.translate('Filter')"
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
            @click:clear="onClear"
          />
        </v-form>
        <v-dialog persistent v-model="productsToAddDialog" v-if="!disabled" width="auto">
          <template v-slot:activator="{ on: onDialog, attrs: attrsDialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                <v-btn
                  class="ml-2"
                  fab
                  small
                  v-bind="{ ...attrsDialog, ...attrsTooltip }"
                  v-on="{ ...onDialog, ...onTooltip }"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>New Promotion Product</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add {{ $i18n.translate("Products") }}</span>
              <v-spacer />
              <v-form @submit.stop.prevent="onProductsToAddFilter">
                <v-text-field
                  class="mr-4"
                  v-model="productsToAdd.search"
                  :label="$i18n.translate('Filter Products')"
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  @click:clear="onProductsToAddClearFilter"
                />
              </v-form>
            </v-card-title>

            <v-card-text>
              <ApiError :errors="errors" />
              <v-data-table
                v-model="productsToAdd.selected"
                show-select
                :items="productsToAdd.items"
                :headers="productAddHeaders"
                :server-items-length="productsToAdd.total"
                :options.sync="productsToAddOptions"
                :loading="productsToAdd.loading"
              >
                <template v-slot:item.updatedDate="{ item }">
                  {{ item.updatedDate | formatDateFromNow }}
                </template>
                <template v-slot:item.effectiveDate="{ item }">
                  <span :inner-html.prop="item | showActive" />
                </template>
              </v-data-table>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn @click="onProductsToAddClose">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" @click="onProductsToAddSubmit">Add</v-btn>
              <v-btn color="primary" @click="onProductGroupsToAddSubmit" :disabled="productsToAdd.selected.length <= 1">
                {{ $i18n.translate("Add Product Group") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="productsToAddCopyAndPasteDialog" v-if="!disabled" width="850">
          <template v-slot:activator="{ on: onDialog, attrs: attrsDialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                <v-btn
                  class="ml-2"
                  fab
                  small
                  v-bind="{ ...attrsDialog, ...attrsTooltip }"
                  v-on="{ ...onDialog, ...onTooltip }"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>Copy &amp; Paste New Products</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Products by Copy &amp; Paste</span>
            </v-card-title>

            <v-card-text>
              <p>
                You can copy and paste model numbers or you can follow a pipe delimited format to import complex
                configurations. If effectivities are included, we attempt to expire old payouts and create new ones
                based on the dates provided.
              </p>
              <v-radio-group v-model="selectedImportFormat">
                <v-radio label="Product model key with flat payout (Model#|123)" value="payout" />
                <v-radio label="Product model key with promotion number (Model#|9000012345)" value="promotion" />
                <v-radio
                  label="Product model key with promotion number and flat payout (Model#|9000012345|123)"
                  value="promotionAndPayout"
                />
                <v-radio
                  label="Product model key with full details [promotion number, flat payout, effective date, expiration date] (Model#|9000012345|123|2023-01-01|2023-12-31)"
                  value="fullDetails"
                />
              </v-radio-group>

              <v-textarea
                label="Copy &amp; Paste Model Numbers"
                v-model="productsToAddCopyAndPaste"
                hint="Delimit model numbers with a space"
                clearable
                auto-grow
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn @click="onProductsToAddCopyAndPasteClose">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                  <v-btn
                    color="primary"
                    class="ml-2"
                    @click="onImportProductsToAddCopyAndPasteSubmit"
                    :loading="isBusy"
                    v-bind="{ ...attrsTooltip }"
                    v-on="{ ...onTooltip }"
                  >
                    {{ $i18n.translate("Add") }}
                  </v-btn>
                </template>
                <span>{{ $i18n.translate("Copy & Paste New Products") }}</span>
              </v-tooltip>
              <v-dialog persistent v-model="productsImportedDialog" v-if="!disabled" width="auto">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ $i18n.translate("Products To Be Imported by Copy & Paste") }}</span>
                  </v-card-title>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="6">
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{ $i18n.translate("Imported") }}</span>
                          </v-card-title>
                          <v-data-table
                            :headers="importedProductsHeaders"
                            :items="productsToCopyAndPaste.imported"
                            height="30vh"
                            :loading="isBusy"
                          >
                            <template v-slot:item.promotionProductPayouts="{ item }">
                              <ul>
                                <li v-for="(promotionProductPayout, i) in item.promotionProductPayouts" :key="i">
                                  $ {{ promotionProductPayout ? promotionProductPayout.payoutAmount : 0 }}
                                  <span v-if="promotionProductPayout.effectiveDate">
                                    Effective on
                                    {{
                                      promotionProductPayout.effectiveDate
                                        | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient)
                                    }}
                                  </span>
                                  <span v-if="promotionProductPayout.expirationDate">
                                    {{ $i18n.translate("Expires on") }}
                                    {{
                                      promotionProductPayout.expirationDate
                                        | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient)
                                    }}
                                  </span>
                                </li>
                              </ul>
                            </template>
                          </v-data-table>
                        </v-card>
                      </v-col>
                      <v-col cols="6">
                        <v-card>
                          <v-card-title>
                            <span class="headline">{{ $i18n.translate("Not Imported") }}</span>
                          </v-card-title>
                          <v-data-table
                            :headers="NotImportedProductsHeaders"
                            :items="productsToCopyAndPaste.notImported"
                            height="30vh"
                            :loading="isBusy"
                          />
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn @click="onProductsImportedClose">
                      {{ $i18n.translate("Cancel") }}
                    </v-btn>
                    <v-btn color="primary" @click="onProductsToAddCopyAndPasteSubmit">
                      {{ $i18n.translate("Add") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="productEditDialog" v-if="!disabled">
          <v-card>
            <v-card-title>
              <span v-if="!editedItem.isGroup" class="headline">
                {{ $i18n.translate("Edit Product -") }}
                {{ editedItem && editedItem.product ? editedItem.product.productKey : "" }}
              </span>
              <span v-else>{{ $i18n.translate("Edit Product Group") }}</span>
            </v-card-title>

            <v-card-text>
              <v-tabs v-model="tab">
                <v-tab>{{ $i18n.translate("Details") }}</v-tab>
                <v-tab>{{ $i18n.translate("Payout Rules") }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <!-- Details Tab -->
                <v-tab-item>
                  <v-form :value="valid" @submit.prevent="onSave" v-model="valid" ref="form">
                    <v-container fluid>
                      <v-row v-if="!editedItem.isGroup">
                        <v-row>
                          <v-col cols="3">
                            <v-row>
                              <v-col cols="12">
                                <DateTimePickerField
                                  v-model="editedItem.effectiveDate"
                                  :disabled="disabled"
                                  label="Visible since"
                                  hint="Optional. Will default to promotion start date."
                                  defaultTime="00:00"
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12">
                                <DateTimePickerField
                                  v-model="editedItem.expirationDate"
                                  :disabled="disabled"
                                  label="Visible until"
                                  hint="Optional. Will default to promotion end date."
                                  defaultTime="23:59"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="9">
                            <v-row>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.minimumClaimableUnits"
                                  label="Minimum Claimable Units"
                                  type="number"
                                  min="0"
                                  clearable
                                />
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.maximumClaimableUnits"
                                  label="Maximum Claimable Units"
                                  type="number"
                                  min="0"
                                  clearable
                                />
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.maximumPayoutAmount"
                                  label="Maximum Cap or Payout for this Product"
                                  type="number"
                                  min="0"
                                  clearable
                                />
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="4">
                                <v-text-field
                                  v-model="editedItem.promotionProductAgreementNumber"
                                  label="Promotion Product Agreement Number"
                                />
                              </v-col>
                              <v-col cols="4">
                                <v-switch
                                  :label="'Enable ' + $i18n.translate('Serial Number') + ' Checks?'"
                                  v-model="editedItem.enableSerialNumbers"
                                  persistent-hint
                                  :hint="
                                    'If enabled, we\'ll require ' +
                                      $i18n.translate('Serial Number') +
                                      ' only if there exists ' +
                                      $i18n.translate('Serial Numbers') +
                                      ' in our DB'
                                  "
                                />
                                <ul>
                                  <li>
                                    <small>
                                      {{ editedItem.totalElements }}
                                      {{ $i18n.translate("Serial Numbers available") }}
                                    </small>
                                  </li>
                                  <li v-if="!editedItem.enableSerialNumbers && editedItem.totalElements > 0">
                                    <small>
                                      <v-icon color="warning">mdi-alert</v-icon>
                                      This product has {{ $i18n.translate("Serial Numbers") }}, but you've disabled
                                      requiring it during claiming
                                    </small>
                                  </li>
                                  <li v-else-if="editedItem.enableSerialNumbers && editedItem.totalElements == 0">
                                    <small
                                      ><v-icon color="warning">mdi-alert</v-icon> This product does not have
                                      {{ $i18n.translate("Serial Numbers") }}, but you've enabled checking for
                                      {{ $i18n.translate("Serial Numbers") }}
                                    </small>
                                  </li>
                                </ul>
                              </v-col>
                              <v-col cols="4">
                                <v-switch
                                  label="Allow Product Price Entry?"
                                  v-model="editedItem.allowProductPriceEntry"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-row>

                      <v-row v-if="!editedItem.isGroup">
                        <v-col cols="12">
                          <ClaimProductDuplicationTypeField
                            v-model="editedItem.claimProductDuplicationType"
                            label="Duplication Check"
                            :class="
                              editedItem.product && editedItem.product.productKey === 'TRADE_IN_PRODUCT'
                                ? ''
                                : 'required'
                            "
                            :rules="
                              editedItem.product && editedItem.product.productKey === 'TRADE_IN_PRODUCT'
                                ? []
                                : rules.claimProductDuplicationType
                            "
                          />
                        </v-col>
                      </v-row>
                      <v-row />

                      <v-row v-if="hasCustomField(1)">
                        <v-col v-for="(customField, index) in selectedProgram.claimProductCustomFields" :key="index">
                          <v-checkbox
                            v-if="hasCustomField(index)"
                            v-model="editedItem[`enableCustomField${index + 1}`]"
                            :label="'Enable ' + customField.name"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="2">
                          <v-checkbox v-model="editedItem.hideQuantity" :label="$i18n.translate('Hide Quantity')" />
                        </v-col>
                        <v-col cols="2">
                          <v-checkbox
                            v-model="editedItem.hideSerialNumber"
                            :label="$i18n.translate('Hide Serial Number')"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="editedItem.isGroup">
                        <v-col cols="8">
                          <v-select
                            v-model="editedItem.products"
                            label="Products Grouped"
                            :items="productsToGroup"
                            item-value="id"
                            item-text="productKey"
                            clearable
                            return-object
                            multiple
                            chips
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-switch
                            label="Combine unpaid units from Past Claims ? "
                            v-model="editedItem.combineUnpaidUnitsFromPastClaims"
                            persistent-hint
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-switch
                            label="Ignore already considered logic "
                            v-model="editedItem.ignoreAlreadyConsideredLogic"
                            persistent-hint
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-tab-item>

                <!-- Payout Rules Tab -->
                <v-tab-item>
                  <v-container fluid>
                    <v-form :value="valid" @submit.prevent="onSave" v-model="valid" ref="form">
                      <v-row class="info">
                        <v-col cols="10">
                          <h3 class="mt-2 white--text">
                            {{ !showRules ? "Product Payouts" : "Rules" }}
                          </h3>
                        </v-col>
                        <v-col cols="1">
                          <v-btn v-if="isRuleEngineBased" @click="onShowRules()">
                            {{ showRules ? "Change to Payout" : "Change to Rules" }}
                          </v-btn>
                        </v-col>
                        <v-col cols="1" class="text-right">
                          <v-tooltip bottom>
                            <template
                              v-slot:activator="{
                                on: onTooltip,
                                attrs: attrsTooltip
                              }"
                            >
                              <v-btn
                                fab
                                small
                                v-bind="{ ...attrsTooltip }"
                                v-on="{ ...onTooltip }"
                                @click="onAddProductPayout(editedItem.isGroup)"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>New Product Payout</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <div>
                        <v-row>
                          <v-col cols="12">
                            <div v-for="(promotionProductPayout, i) in editedPromotionProductPayouts" :key="i">
                              <v-card class="mt-2 pl-2" v-if="!showRules">
                                <v-card-title class="mb-n10 ">
                                  <v-spacer />
                                  <v-icon v-if="!disabled" @click="onDeleteProductPayout(promotionProductPayout)">
                                    mdi-delete
                                  </v-icon>
                                </v-card-title>
                                <v-card-text>
                                  <v-row>
                                    <b>{{ promotionProductPayout | payoutVerbiage(promotionCurrency) }}</b>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="2" class="ma-1">
                                      <v-row>
                                        <DateTimePickerField
                                          v-model="promotionProductPayout.effectiveDate"
                                          :disabled="disabled"
                                          label="Effective Date"
                                          hint="Optional.  Will default to promotion start date."
                                          defaultTime="00:00"
                                        />
                                      </v-row>
                                      <v-row>
                                        <DateTimePickerField
                                          v-model="promotionProductPayout.expirationDate"
                                          :disabled="disabled"
                                          label="Expiration Date"
                                          hint="Optional.  Will default to promotion end date."
                                          defaultTime="23:59"
                                        />
                                      </v-row>
                                    </v-col>

                                    <v-col cols="3" class="ma-1">
                                      <v-row>
                                        <v-select
                                          :items="computedPayoutTypes"
                                          v-model="promotionProductPayout.payoutType"
                                          label="Payout Type"
                                          :disabled="disabled"
                                          item-value="name"
                                          item-text="description"
                                          return-object
                                          :rules="rules.payoutType"
                                          @input="v => onInputPayoutType(v, promotionProductPayout)"
                                        />
                                      </v-row>
                                      <v-row>
                                        <v-select
                                          v-if="showPayoutProductMethodTypesField(promotionProductPayout)"
                                          :items="payoutProductMethodTypes"
                                          v-model="promotionProductPayout.payoutProductMethodType"
                                          label="Payout Method Type"
                                          :disabled="disabled"
                                          item-value="name"
                                          item-text="description"
                                          return-object
                                          :rules="rules.payoutProductMethodType"
                                        />
                                      </v-row>
                                    </v-col>

                                    <v-col cols="1" class="ma-1">
                                      <v-row>
                                        <v-text-field
                                          v-if="showPayoutAmountField(promotionProductPayout)"
                                          v-model="promotionProductPayout.payoutAmount"
                                          label="Payout Amount"
                                          type="number"
                                          min="0"
                                          max="49999"
                                          :disabled="disabled"
                                          :rules="rules.payoutAmount"
                                        />
                                        <v-text-field
                                          v-else
                                          v-model="promotionProductPayout.payoutProductQuantity"
                                          label="Quantity"
                                          type="number"
                                          min="1"
                                          max="9999"
                                          :disabled="disabled"
                                          :rules="[v => validateQuantity(v, promotionProductPayout)]"
                                        />
                                      </v-row>
                                      <v-row>
                                        <v-text-field
                                          v-if="showThresholdField(promotionProductPayout)"
                                          v-model="promotionProductPayout.payoutProductThreshold"
                                          label="Threshold"
                                          type="number"
                                          min="1"
                                          max="9999"
                                          :disabled="disabled"
                                          :rules="rules.payoutProductThreshold"
                                        />
                                      </v-row>
                                    </v-col>
                                    <v-col
                                      cols="2"
                                      class="ma-1"
                                      v-if="
                                        promotionProductPayout.forcedAwardVehicle &&
                                          promotionProductPayout &&
                                          promotionProductPayout.payoutType &&
                                          promotionProductPayout.payoutType.name == 'PRODUCT'
                                      "
                                    >
                                      <v-row>
                                        <StoreProductsField
                                          v-model="promotionProductPayout.payoutStoreCatalogProduct"
                                          :rules="rules.payoutStoreCatalogProduct"
                                          :country="promotionCountry"
                                          :awardVehicle="promotionProductPayout.forcedAwardVehicle"
                                        >
                                          <template v-slot:selection="{ item }">
                                            <span>
                                              {{
                                                item.storeProduct.productNumber
                                                  ? item.storeProduct.productNumber + " - "
                                                  : ""
                                              }}
                                              {{ item.storeProduct.name }}
                                              ({{ item.calculatedPriceAmount | toNumber(2, selectedProgram) }}
                                              {{
                                                item.calculatedPriceAmountCurrency
                                                  ? item.calculatedPriceAmountCurrency
                                                  : ""
                                              }})
                                            </span>
                                          </template>
                                        </StoreProductsField>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="2" class="ma-1" v-else>
                                      <v-row>
                                        <v-text-field :value="promotionCurrency" label="Currency" readonly />
                                      </v-row>
                                    </v-col>
                                    <v-col cols="3" class="ma-1">
                                      <v-row>
                                        <AwardVehicleField
                                          v-model="promotionProductPayout.forcedAwardVehicle"
                                          label="Forced Award Vehicle"
                                          hint="To force the award vehicle for this product payout"
                                          :disabled="disabled"
                                          v-if="
                                            promotionProductPayout &&
                                              promotionProductPayout.payoutType &&
                                              promotionProductPayout.payoutType.name
                                          "
                                          class="required"
                                          :awardVehicleTypes="
                                            promotionProductPayout.payoutType.supportedAwardVehicleTypes
                                          "
                                          :country="promotionCountry"
                                          :rules="[v => validateAwardVehicle(v, promotionProductPayout)]"
                                          @input="promotionProductPayout.payoutStoreCatalogProduct = null"
                                        />
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="6">
                                      <v-text-field
                                        v-model="promotionProductPayout.payoutDescription"
                                        label="Payout Description"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                              <div v-else>
                                <v-row>
                                  <v-col>
                                    <RuleTable
                                      @deleteProductPayout="onDeleteProductPayout(promotionProductPayout)"
                                      v-model="promotionProductPayout.ruleGroup.rules"
                                    />
                                  </v-col>
                                </v-row>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-form>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn @click="onClose">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" @click="onSave()" :disabled="!valid">{{ $i18n.translate("Save") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="deleteProductDialog" max-width="650px">
          <v-card>
            <v-card-title>
              <span class="headline">Are you sure you want to Delete Product?</span>
            </v-card-title>
            <v-card-text>
              <p>
                If deleting this product fails, it is because a claim has been made against this product. In this case,
                the product will be expired instead of deleted..
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="onCancelDeleteDialog()">Cancel</v-btn>
              <v-btn color="primary" @click="onDeleteProduct()">Agree</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="expireProductDialog" max-width="650px">
          <v-card>
            <v-card-title>
              <span class="headline"> Are you sure you want to Expire Product?</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-radio-group v-model="expireOption">
                    <v-radio label="Immediate" value="1" />
                    <v-radio label="Future" value="2" />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <DateTimePickerField
                    v-if="expireOption == 2"
                    v-model="expireDate"
                    label="Expiration Date"
                    defaultTime="23:59"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="onCancelExpireDialog()">Cancel</v-btn>
              <v-btn
                color="primary"
                @click="onExpireProduct()"
                :disabled="expireOption == 2 && expireDate == undefined"
              >
                Agree
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.product.productKey="{ item }">
      <span v-if="!item.isGroup" :class="isEffective(item) ? 'text-decoration-none' : 'text-decoration-line-through'">
        {{ item.product.productKey }}
      </span>
      <span v-else>
        <ul v-if="item.products && item.products.length > 0">
          <li
            v-for="(product, i) in item.products"
            :key="i"
            :class="isEffective(item) ? 'text-decoration-none' : 'text-decoration-line-through'"
          >
            {{ product.productKey }}
          </li>
        </ul>
      </span>
    </template>
    <template v-slot:item.customFields="{ item }">
      <span
        v-for="(productCustomField, i) in selectedProgram.programGroup.productCustomFields"
        :key="productCustomField.name"
      >
        <span v-if="productCustomField.name"
          >{{ $i18n.translate(productCustomField.name) }}: {{ item.product["customFieldValue" + (i + 1)] }}<br
        /></span>
      </span>
    </template>
    <template v-slot:item.requireSerialNumber="{ item }">
      <v-icon v-if="!item.isGroup && item.enableSerialNumbers" color="green">mdi-check</v-icon>
      <v-icon v-else-if="!item.isGroup" color="red">mdi-close</v-icon>
      <span v-else> - </span>
    </template>
    <template v-slot:item.effective="{ item }">
      <v-icon v-if="item && isEffective(item)" color="green" @click="test()">mdi-check</v-icon>
      <v-icon v-else color="red">mdi-close</v-icon>
    </template>
    <template v-slot:item.maximumClaimableUnits="{ item }">
      <span v-if="!item.isGroup" :class="isEffective(item) ? 'text-decoration-none' : 'text-decoration-line-through'">
        <span v-if="item.minimumClaimableUnits && item.maximumClaimableUnits">
          Min of {{ item.minimumClaimableUnits }} and Max of {{ item.maximumClaimableUnits }} Unit(s)
        </span>
        <span v-else-if="item.minimumClaimableUnits"> Min of {{ item.minimumClaimableUnits }} Unit(s) </span>
        <span v-else-if="item.maximumClaimableUnits"> Max of {{ item.maximumClaimableUnits }} Unit(s) </span>
        <span v-else>No Limits</span>
      </span>
      <span v-else> - </span>
    </template>

    <template v-slot:item.maximumPayoutAmount="{ item }">
      <span v-if="!item.isGroup" :class="isEffective(item) ? 'text-decoration-none' : 'text-decoration-line-through'">
        <span v-if="item.maximumPayoutAmount">
          {{ item.maximumPayoutAmount | toCurrency }}
        </span>
        <span v-else>
          No Cap
        </span>
      </span>
      <span v-else> - </span>
    </template>

    <template v-slot:item.payout="{ item }">
      <span v-if="byRule(item)">By Rule</span>
      <ol v-else-if="item.promotionProductPayouts && item.promotionProductPayouts.length > 0">
        <li
          v-for="(promotionProductPayout, i) in item.promotionProductPayouts"
          :key="i"
          :class="isEffective(item) ? 'text-decoration-none' : 'text-decoration-line-through'"
        >
          {{ promotionProductPayout | payoutVerbiage(promotionCurrency) }}
        </li>
      </ol>
      <span v-else>No Payout</span>
    </template>

    <template v-slot:item.simplePayout="{ item }">
      <span v-if="byRule(item)">By Rule</span>
      <ol v-if="item.promotionProductPayouts && item.promotionProductPayouts.length > 0">
        <li
          v-for="(promotionProductPayout, i) in item.promotionProductPayouts"
          :key="i"
          :class="isEffective(item) ? 'text-decoration-none' : 'text-decoration-line-through'"
        >
          {{ promotionProductPayout.payoutAmount | toNumber(2) }} {{ promotionCurrency }}
        </li>
      </ol>
      <span v-else>No Payout</span>
    </template>

    <template v-slot:item.claimProductDuplicationType="{ item }">
      <span v-if="!item.isGroup" :class="isEffective(item) ? 'text-decoration-none' : 'text-decoration-line-through'">
        {{ item.claimProductDuplicationType ? item.claimProductDuplicationType.description : "" }}
      </span>
      <span v-else> - </span>
    </template>

    <template v-slot:item.currency> {{ promotionCountry.currency }} </template>

    <template v-if="!hideActions" v-slot:item.actions="{ item }">
      <div class="text-no-wrap">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-1 mr-1" v-bind="attrs" v-on="on" @click="onEditItem(item)">
              mdi-pencil
            </v-icon>
          </template>
          <span>Edit</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-1 mr-1" v-bind="attrs" v-on="on" @click="onDeleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item && !item.isGroup && !item.expirationDate">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-1 mr-1" v-bind="attrs" v-on="on" @click="onExpireItem(item)">
              mdi-timer-outline
            </v-icon>
          </template>
          <span>Expire</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item && !item.isGroup && item.expirationDate">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-1 mr-1" v-bind="attrs" v-on="on" @click="onActivateItem(item)">
              mdi-timer-off-outline
            </v-icon>
          </template>
          <span>
            Activate
            <span v-if="isEffective(item)">
              (Expires on {{ item.expirationDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }})
            </span>
          </span>
        </v-tooltip>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import RuleTable from "@/gapp-components/components/tables/RuleTable";
import ApiService from "@/gapp-components/services/api.service";
import UtilService from "@/gapp-components/services/util.service";
import StoreProductsField from "@/gapp-components/components/common/store/StoreProductsField";
import ApiError from "@/gapp-components/components/display/ApiError";
import AwardVehicleField from "@/gapp-components/components/fields/AwardVehicleField";
import ClaimProductDuplicationTypeField from "@/gapp-components/components/fields/ClaimProductDuplicationTypeField";
import DateTimePickerField from "@/gapp-components/components/fields/DateTimePickerField";
import moment from "moment-timezone";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  components: {
    AwardVehicleField,
    DateTimePickerField,
    ClaimProductDuplicationTypeField,
    StoreProductsField,
    ApiError,
    RuleTable
  },
  name: "PromotionProductsTable",
  props: {
    promotionEffectiveDate: String,
    promotionExpirationDate: String,
    promotionId: Number,
    simplePayouts: {
      type: Boolean,
      default: false
    },
    isRuleEngineBased: {
      type: Boolean,
      default: false
    },
    promotionCountry: {
      type: Object,
      required: true
    },
    promotionCurrency: {
      type: String,
      required: true
    },
    value: Object,
    disabled: Boolean,
    hideTitle: Boolean,
    hideActions: Boolean,
    availableAwardVehicles: Array,
    productsToAddHeaders: {
      type: Array
    }
  },
  data: () => ({
    valid: false,
    loaded: false,
    deleteProductDialog: false,

    expireProductDialog: false,
    expireOption: "1",
    expireDate: "",

    productEditDialog: false,
    productsToAddDialog: false,
    productsToAddCopyAndPasteDialog: false,
    productsImportedDialog: false,
    productAddHeaders: [],
    showRules: false,
    selectedImportFormat: "fullDetails",
    headers: [
      { text: "Model", value: "product.productKey" },
      { text: "Details", value: "customFields", sort: false },
      {
        text: "Effective?",
        value: "effective",
        align: "center"
      },
      {
        text: "Serial Enabled?",
        value: "requireSerialNumber",
        align: "center"
      },
      {
        text: "Min/Max Claiming?",
        value: "maximumClaimableUnits",
        align: "center"
      },
      { text: "Max Payout?", value: "maximumPayoutAmount", align: "center" },
      { text: "Dup Check", value: "claimProductDuplicationType" },
      { text: "Payout", value: "simplePayout" },
      { text: "Payout", value: "payout" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    importedProductsHeaders: [
      { text: "Product Number", value: "product.productKey" },
      { text: "Promotion Product Agreement Number", value: "promotionProductAgreementNumber" },
      { text: "Payout", value: "promotionProductPayouts" }
    ],
    NotImportedProductsHeaders: [{ text: "Product Number", value: "serialNumber" }],
    rules: {
      claimProductDuplicationType: [v => !!v || "Duplication Check is required"],
      payoutStoreCatalogProduct: [v => !!v || "A product should be selected"],
      payoutType: [v => !!v || "Payout Type is required"],
      payoutAmount: [
        v => !!v || "Payout Amount is required",
        v => (!!v && v < 49999) || "Payout Amount must be lower than 49999"
      ],
      payoutProductThreshold: [v => !!v || "Threshold is required"],
      payoutProductQuantity: [v => !!v || "Quantity is required"]
    },
    isBusy: false,
    items: {
      existing: [],
      deleted: []
    },
    editedIndex: -1,
    editedItem: { promotionProduct: {} },
    editedPromotionProductPayouts: [],
    defaultItem: { promotionProduct: {} },

    payoutTypes: [],
    payoutProductMethodTypes: [],

    productsToAdd: {
      search: "",
      loading: false,
      selected: [],
      items: [],
      nameFilter: "",

      total: 0
    },
    productsToAddOptions: {
      itemsPerPage: 10
    },

    productsToAddCopyAndPaste: null,

    productsToCopyAndPaste: {
      imported: [],
      notImported: []
    },

    claimProductDuplicationKeyDefault: {},
    claimProductDuplications: [],

    productsToGroup: [],
    promotionProductPayoutIsGroup: false,
    errors: {},

    searchField: "",
    search: "",
    tab: 0
  }),

  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Promotion Product" : "Edit Promotion Product Field";
    },
    computedPayoutTypes() {
      if (this.promotionProductPayoutIsGroup) {
        return this.payoutTypes.filter(payoutType => payoutType.supportsProductGroup == true);
      } else {
        return this.payoutTypes;
      }
    },
    computedHeaders() {
      return this.headers.reduce((acc, cur) => {
        if (
          (this.hideActions && cur.value && cur.value == "actions") ||
          (this.simplePayouts && cur.value && cur.value == "payout") ||
          (!this.simplePayouts && cur.value && cur.value == "simplePayout")
        ) {
          return acc;
        }
        acc.push(cur);
        return acc;
      }, []);
    }
  },

  watch: {
    productEditDialog(val) {
      val || this.onClose();
    },
    productsToAddOptions: {
      handler() {
        this.getProductsToAddData();
      },
      deep: true
    },
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        this.items = v;
      }
    }
  },

  created() {
    this.initialize();
    this.initProductAddHeaders();
  },

  methods: {
    initialize() {
      this.isBusy = true;

      Promise.all([
        ApiService.get("/api/types/payouts").then(({ data }) => {
          this.payoutTypes = data;
        }),
        ApiService.get("/api/types/productPayoutMethods").then(({ data }) => {
          this.payoutProductMethodTypes = data;
        }),
        ApiService.get("/api/types/claimProductDuplications").then(({ data }) => {
          this.claimProductDuplications = data;
          let result = data.filter(item => item.name == "MODEL_SERIAL_NUMBER");
          this.claimProductDuplicationKeyDefault = result[0];
        })
      ])
        .then(() => {
          this.loaded = true;
        })
        .finally(() => {
          this.isBusy = false;

          this.fetchProductsToGroup();
        });
    },

    initProductAddHeaders() {
      this.productAddHeaders = [];

      if (this.productToAddHeaders && this.productToAddHeaders.length > 0) {
        this.productAddHeaders = this.productToAddHeaders;
        return;
      }

      this.productAddHeaders.push({
        value: "productKey",
        text: this.$i18n.translate("Key"),
        sortable: true
      });
      this.productAddHeaders.push({
        value: "name",
        text: this.$i18n.translate("Name"),
        sortable: true
      });
      this.productAddHeaders.push({
        value: "productCategory.name",
        text: this.$i18n.translate("Category"),
        sortable: false
      });
      this.productAddHeaders.push({
        value: "totalElements",
        text: this.$i18n.translate("Serial Number") + " " + this.$i18n.translate("Count"),
        align: "right",
        sortable: false
      });

      let size = this.selectedProgram.programGroup.productCustomFields.length;
      for (let i = 1; i <= size; i++) {
        let productCustomField = this.selectedProgram.programGroup.productCustomFields[i - 1];
        if (productCustomField.name) {
          this.productAddHeaders.push({
            value: "customFieldValue" + i,
            text: this.$i18n.translate(productCustomField.name),
            sortable: true
          });
        }
      }

      this.productAddHeaders.push({
        value: "effectiveDate",
        text: this.$i18n.translate("Active"),
        align: "center",
        sortable: true
      });
      this.productAddHeaders.push({
        value: "updatedDate",
        text: this.$i18n.translate("Last Updated"),
        sortable: true
      });
    },

    onSearch() {
      this.search = this.searchField;
    },

    onClear() {
      this.searchField = "";
      this.search = "";
    },

    fetchProductsToGroup() {
      this.items.existing.forEach(item => {
        if (!item.isGroup) {
          this.productsToGroup.push(item.product);
        }
      });
    },

    onInput() {
      this.$emit("input", this.items);
    },

    onAddProductPayout(isGroup) {
      this.promotionProductPayoutIsGroup = isGroup;
      let newItem = {
        payoutProductMethodType: { name: "EACH" },
        payoutAmount: 0,
        ruleGroup: {
          rules: [
            {
              ruleCondition: {},
              errorMessage: "",
              ruleActions: [
                {
                  actionKey: "generateMoneyPayoutAction",
                  context: { generatePayoutActionType: "CLAIM_PRODUCT_PAYOUT", expression: "" },
                  awardVehicle: null,
                  awardVehicleId: null
                }
              ]
            }
          ]
        }
      };
      if (
        this.editedPromotionProductPayouts.length > 0 &&
        this.editedPromotionProductPayouts[this.editedPromotionProductPayouts.length - 1].expirationDate
      ) {
        newItem.effectiveDate = this.editedPromotionProductPayouts[
          this.editedPromotionProductPayouts.length - 1
        ].expirationDate;
      }
      this.editedPromotionProductPayouts.push(newItem);
    },

    onEditItem(item) {
      this.promotionProductPayoutIsGroup = item.isGroup;
      this.editedIndex = this.items.existing.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedPromotionProductPayouts = Object.assign([], item.promotionProductPayouts);

      this.showRules = this.byRule(item);

      if (this.showRules) {
        this.editedPromotionProductPayouts.forEach(payout => {
          if (payout.ruleGroup && payout.ruleGroup.rules && payout.ruleGroup.rules.length > 0) {
            payout.ruleGroup.rules[0].effectiveDate = payout.effectiveDate;
            payout.ruleGroup.rules[0].expirationDate = payout.expirationDate;
            payout.hasRule = true;
          }
        });
      }

      ApiService.post("/api/productSerialNumbers/search?page=0&size=1", {
        product: this.editedItem.product
      }).then(({ data }) => {
        this.editedItem.totalElements = data.totalElements;

        if (this.editedPromotionProductPayouts.length == 0) {
          this.onAddProductPayout(item.isGroup);
        }

        this.productEditDialog = true;
      });
    },

    onDeleteProductPayout(productPayout) {
      const index = this.editedPromotionProductPayouts.indexOf(productPayout);
      this.editedPromotionProductPayouts.splice(index, 1);
    },
    onDeleteItem(item) {
      this.deleteProductDialog = true;
      this.editedIndex = this.items.existing.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.loaded) {
        this.$emit("change");
      }
    },
    onDeleteProduct() {
      let deletedItems = this.items.existing.splice(this.editedIndex, 1);
      deletedItems = deletedItems.reduce((acc, cur) => {
        if (cur.id) {
          acc.push(cur);
        }
        return acc;
      }, []);
      this.items.deleted = this.items.deleted.concat(deletedItems);
      this.$emit("input", this.items);
      this.deleteProductDialog = false;
    },
    onCancelDeleteDialog() {
      this.deleteProductDialog = false;
    },
    onExpireItem(item) {
      this.expireProductDialog = true;
      this.editedIndex = this.items.existing.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    onExpireProduct() {
      if (this.expireOption == "1") {
        this.editedItem.expirationDate = moment()
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      } else {
        this.editedItem.expirationDate = this.expireDate;
      }
      this.editedItem.markAsExpired = true;
      if (this.editedIndex > -1) {
        Object.assign(this.items.existing[this.editedIndex], this.editedItem);
        this.$emit("input", this.items);
      }
      this.expireProductDialog = false;
    },
    onActivateItem(item) {
      this.editedIndex = this.items.existing.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.markAsExpired = false;
      this.editedItem.expirationDate = null;
      this.editedItem.dirty = true;
      Object.assign(this.items.existing[this.editedIndex], this.editedItem);
      this.$emit("input", this.items);
    },
    onCancelExpireDialog() {
      this.expireProductDialog = false;
      this.expireOption = ";1";
    },
    onClose() {
      this.productEditDialog = false;
      this.showRules = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onProductsToAddFilter() {
      this.getProductsToAddData();
    },

    onProductsToAddClearFilter() {
      this.productsToAdd.search = "";
      this.getProductsToAddData();
    },

    onProductGroupsToAddSubmit() {
      //validation
      this.errors = {};
      let promotionProducts = this.items.existing.filter(item => !item.isGroup);
      let canCreateGroup = true;
      this.productsToAdd.selected.some(product => {
        let found = promotionProducts.findIndex(needle => needle.product.id == product.id);
        if (found == -1) {
          canCreateGroup = false;
        }
      });
      if (canCreateGroup) {
        let obj = {
          dirty: true,
          isGroup: true,
          products: this.productsToAdd.selected,
          claimProductDuplicationType: this.claimProductDuplicationKeyDefault
        };
        this.items.existing.push(obj);
        this.productsToAddDialog = false;
        this.productsToAdd.selected = [];
      } else {
        this.errors = ApiService.createError("The selected products should be previously added individually.");
      }
    },

    onProductsToAddSubmit() {
      let promotionProductGroups = this.items.existing.filter(item => item.isGroup);
      let promotionProducts = this.items.existing.filter(item => !item.isGroup);
      let promiseArray = [];
      this.productsToAdd.selected.some(product => {
        let found = promotionProducts.findIndex(needle => needle.product.id == product.id);
        if (found == -1) {
          promiseArray.push(
            ApiService.post("/api/productSerialNumbers/search?page=0&size=1", {
              product: product
            }).then(({ data }) => {
              let flag = false;
              if (!data.empty) {
                flag = true;
                this.claimProductDuplicationKeyDefault = this.fetchDuplicationCheck("MODEL_SERIAL_NUMBER");
              } else {
                this.claimProductDuplicationKeyDefault = this.fetchDuplicationCheck("PARTICIPANT_MODEL_INVOICE");
              }
              promotionProducts = promotionProducts.concat([
                {
                  dirty: true,
                  isGroup: false,
                  product: product,
                  enableSerialNumbers: flag,
                  claimProductDuplicationType:
                    product.productKey === "TRADE_IN_PRODUCT" ? undefined : this.claimProductDuplicationKeyDefault
                }
              ]);
            })
          );
        }
      });
      Promise.all(promiseArray).then(() => {
        this.items.existing = promotionProducts;
        promotionProductGroups.forEach(item => this.items.existing.push(item));
        this.productsToAddDialog = false;
        this.productsToAdd.selected = [];
        this.fetchProductsToGroup();
      });
    },

    fetchDuplicationCheck(name) {
      let result = this.claimProductDuplications.filter(item => item.name == name);
      return result[0];
    },
    onProductsToAddClose() {
      this.productsToAddDialog = false;
    },

    onProductsToAddCopyAndPasteSubmit() {
      this.items.existing = this.items.existing.concat(this.productsToCopyAndPaste.imported);
      this.productsToAddCopyAndPaste = "";
      this.productsImportedDialog = false;
      this.productsToAddCopyAndPasteDialog = false;
      this.onProductsImportedClose();
    },

    onImportProductsToAddCopyAndPasteSubmit() {
      // Fetch products by deliminted model number
      if (!this.productsToAddCopyAndPaste || this.productsToAddCopyAndPaste.trim().length === 0) {
        return;
      }
      this.isBusy = true;
      let modelNumbers = this.productsToAddCopyAndPaste
        .replace(/[\n\r\t,]/g, " ")
        .split(" ")
        .filter(item => item.length > 0);
      let notFoundModelNumbers;

      // Search for product keys to see if they exist in our system
      ApiService.post("/api/products/search?size=500", {
        productKeys: modelNumbers.map(item => item.split("|")[0])
      })
        .then(({ data }) => {
          // Keep track of model numbers that don't exist in our system
          notFoundModelNumbers = modelNumbers.filter(
            modelNumber =>
              !data.content.some(
                product =>
                  product.modelNumber === modelNumber.split("|")[0] || product.productKey === modelNumber.split("|")[0]
              )
          );

          // Existing products within promotion
          let promotionProducts = this.items.existing.slice();

          // Loop through products
          let promiseArray = [];
          data.content.forEach(product => {
            // See if a product already exists on the promotion
            let foundExistingProduct = promotionProducts.findIndex(needle => {
              if (needle.products) {
                return needle.products.some(p => p.id == product.id);
              } else {
                return needle.product.id == product.id;
              }
            });
            let item = modelNumbers.find(item => {
              let valueToSearch = item.split("|")[0];
              return (
                valueToSearch &&
                valueToSearch != "" &&
                (valueToSearch == product.productKey || valueToSearch == product.modelNumber)
              );
            });

            // Determine item used during copy and paste (which might include additional information)
            let parts = item ? item.split("|") : [];

            // Extraer los diferentes componentes según el formato seleccionado
            let promotionProductAgreementNumber = null;
            let payoutAmount = null;
            let effectiveDate = null;
            let expirationDate = null;
            let flag = false;
            let duplicationKey = null;
            switch (this.selectedImportFormat) {
              case "payout":
                payoutAmount = parts[1];
                break;

              case "promotion":
                promotionProductAgreementNumber = parts[1];
                break;

              case "promotionAndPayout":
                promotionProductAgreementNumber = parts[1];
                payoutAmount = parts[2];
                break;

              case "fullDetails":
                promotionProductAgreementNumber = parts[1];
                payoutAmount = parts[2];
                effectiveDate = parts[3];
                expirationDate = parts[4];
                break;

              default:
                break;
            }
            let promotionProductPayout = null;
            // If there is a payout amount, then calculate it
            if (payoutAmount) {
              promotionProductPayout = {
                payoutAmount: payoutAmount,
                payoutType: {
                  name: "FLAT_AMOUNT_PER_UNIT_CLAIMED"
                }
              };

              if (effectiveDate) {
                promotionProductPayout.effectiveDate = moment
                  .tz(effectiveDate, this.selectedClient.timezone)
                  .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
              }
              if (expirationDate) {
                promotionProductPayout.expirationDate = moment
                  .tz(expirationDate, this.selectedClient.timezone)
                  .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
              }
            }
            // If we couldn't find an existing product, then create one
            if (foundExistingProduct == -1) {
              // Determine default claim duplication key by seeing if product serial numbers exist
              promiseArray.push(
                ApiService.post("/api/productSerialNumbers/search?page=0&size=1", {
                  product: product
                }).then(response => {
                  if (!response.data.empty) {
                    flag = true;
                    duplicationKey = this.fetchDuplicationCheck("MODEL_SERIAL_NUMBER");
                  } else {
                    duplicationKey = this.fetchDuplicationCheck("PARTICIPANT_MODEL_INVOICE");
                  }

                  this.productsToCopyAndPaste.imported = this.productsToCopyAndPaste.imported.concat([
                    {
                      dirty: true,
                      isGroup: false,
                      product: product,
                      enableSerialNumbers: flag,
                      claimProductDuplicationType: duplicationKey,
                      promotionProductAgreementNumber: promotionProductAgreementNumber,
                      promotionProductPayouts: promotionProductPayout ? [promotionProductPayout] : []
                    }
                  ]);
                })
              );
            } else {
              //  Update promotion product agreement number
              if (promotionProductAgreementNumber && promotionProductAgreementNumber.length > 0) {
                promotionProducts[
                  foundExistingProduct
                ].promotionProductAgreementNumber = promotionProductAgreementNumber;
              }
              // If we have payout information included, then lets attempt to add it, if it doesn't exist
              if (promotionProductPayout) {
                // Attempt to see if payout information already exists
                if (promotionProducts[foundExistingProduct].promotionProductPayouts) {
                  let addPromotionProductPayout = false;
                  promotionProducts[foundExistingProduct].promotionProductPayouts.forEach(payout => {
                    if (payout.payoutAmount != promotionProductPayout.payoutAmount) {
                      // If the supplied item has an effective date, then attempt to expire the old and add the new
                      if (promotionProductPayout.effectiveDate) {
                        if (!payout.expirationDate) {
                          payout.expirationDate = moment
                            .tz(promotionProductPayout.effectiveDate, this.selectedClient.timezone)
                            .subtract(1, "second")
                            .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
                          addPromotionProductPayout = true;
                        }
                      } else if (
                        !promotionProductPayout.effectiveDate &&
                        !promotionProductPayout.expirationDate &&
                        !payout.effectiveDate &&
                        !payout.expirationDate
                      ) {
                        payout.payoutAmount = promotionProductPayout.payoutAmount;
                      }
                    }
                  });
                  if (addPromotionProductPayout) {
                    promotionProducts[foundExistingProduct].promotionProductPayouts.push(promotionProductPayout);
                  }
                }
              }
            }
          });
          Promise.all(promiseArray).then(() => {
            this.productsImportedDialog = true;
          });
        })
        .finally(() => {
          this.productsToCopyAndPaste.notImported.push(
            ...notFoundModelNumbers.map(modelNumber => ({ serialNumber: modelNumber }))
          );
          this.isBusy = false;
        });
    },

    onShowRules() {
      this.showRules = !this.showRules;
      if (this.showRules) {
        this.ensureRuleGroupForPayouts();
      }
    },

    ensureRuleGroupForPayouts() {
      this.editedItem?.promotionProductPayouts?.forEach(payout => {
        if (!payout.ruleGroup) {
          payout.ruleGroup = {
            rules: [
              {
                effectiveDate: payout.effectiveDate,
                expirationDate: payout.expirationDate,
                ruleCondition: {},
                errorMessage: "",
                ruleActions: [
                  {
                    actionKey: "generateMoneyPayoutAction",
                    context: { generatePayoutActionType: "CLAIM_PRODUCT_PAYOUT", expression: "" },
                    awardVehicle: null,
                    awardVehicleId: null
                  }
                ]
              }
            ]
          };
        }
      });
    },

    onProductsToAddCopyAndPasteClose() {
      this.productsToAddCopyAndPasteDialog = false;
    },

    onProductsImportedClose() {
      this.productsToCopyAndPaste = {
        imported: [],
        notImported: []
      };
      this.productsImportedDialog = false;
    },

    onSave() {
      this.editedItem.dirty = true;
      this.editedItem.promotionProductPayouts = this.editedPromotionProductPayouts;
      this.editedItem.promotionProductPayouts.forEach(payout => {
        payout.hasRule = this.showRules;
        if (this.showRules) {
          let ruleGroupCopy = JSON.parse(JSON.stringify(payout.ruleGroup));
          ruleGroupCopy.rules = ruleGroupCopy.rules.filter(item => !item.toBeDeleted);
          ruleGroupCopy.rules.forEach(item => {
            payout.effectiveDate = item.effectiveDate;
            payout.expirationDate = item.expirationDate;
            payout.hasRule = true;
            if (item.ruleActions[0].context) {
              item.ruleActions[0].rule = { id: item.id };
              item.ruleActions[0].actionKey = "generateMoneyPayoutAction";
              if (item.ruleActions[0].context.awardVehicle) {
                item.ruleActions[0].context.awardVehicleId = item.ruleActions[0].context.awardVehicle.id;
              }
              delete item.ruleActions[0].context.awardVehicle;
              item.ruleActions[0].context.generatePayoutActionType = "CLAIM_PRODUCT_PAYOUT";
              item.ruleActions[0].stringContext = JSON.stringify(item.ruleActions[0].context);
            }
          });
          payout.ruleGroup = ruleGroupCopy;
          payout.forcedAwardVehicle = null;
          payout.payoutAmount = null;
          payout.payoutDescription = null;
          payout.payoutProductMethodType = null;
          payout.payoutType = null;
        } else {
          payout.ruleGroup = null;
        }
      });
      if (this.editedIndex > -1) {
        Object.assign(this.items.existing[this.editedIndex], this.editedItem);
      } else {
        this.items.existing.push(this.editedItem);
      }
      this.$emit("input", this.items);
      if (this.loaded) {
        this.$emit("change");
      }
      this.onClose();
    },

    getProductsToAddData() {
      this.productsToAdd.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.productsToAddOptions;

      let filters = {};
      if (this.productsToAdd.search && this.productsToAdd.search.length > 0) {
        filters.keyword = this.productsToAdd.search;
      }

      ApiService.post(
        "/api/products/search?size=" +
          itemsPerPage +
          "&page=" +
          (page - 1) +
          (sortBy && sortBy.length > 0
            ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
            : ""),
        filters
      )
        .then(({ data }) => {
          this.productsToAdd.items = data.content;

          let promiseArray = [];
          for (let p of this.productsToAdd.items) {
            promiseArray.push(
              ApiService.post("/api/productSerialNumbers/search?page=0&size=1", {
                product: p
              }).then(response => {
                p.totalElements = response.data.totalElements;
              })
            );
          }
          Promise.all(promiseArray).then(() => {
            this.productsToAdd.loading = false;
            this.productsToAdd.total = data.totalElements;
          });
        })
        .catch(() => {
          this.productsToAdd.loading = false;
        });
    },
    onInputPayoutType(v, promotionProductPayout) {
      if (v.name == "PRODUCT") {
        Vue.set(promotionProductPayout, "payoutAmount", 0);
        Vue.set(promotionProductPayout, "payoutProductMethodType", { name: "EACH" });
        Vue.set(promotionProductPayout, "payoutProductThreshold", 1);
        Vue.set(promotionProductPayout, "payoutProductQuantity", 1);
        Vue.set(promotionProductPayout, "payoutStoreCatalogProduct", undefined);
      }
      Vue.set(promotionProductPayout, "forcedAwardVehicle", undefined);
      this.$nextTick(() => {
        this.$refs.form.validate();
      });
    },
    validateAwardVehicle(v, promotionProductPayout) {
      if (!v && ["PRODUCT", "COUPON_CODE"].includes(promotionProductPayout.payoutType.name)) {
        return "Override Award Vehicle is required";
      } else {
        return true;
      }
    },
    validateQuantity(v, promotionProductPayout) {
      if (promotionProductPayout.payoutProductMethodType.name == "EVERY" && v == 1) {
        return "Quantity cannot be 1 when EVERY is selected";
      }
      if (v == 0) {
        return "Quantity should be greater than 0.";
      }
      return true;
    },
    isEffective(objectWithEffectivity) {
      return UtilService.isEffective(objectWithEffectivity, null);
    },
    showPayoutProductMethodTypesField(promotionProductPayout) {
      return (
        promotionProductPayout &&
        promotionProductPayout.payoutType &&
        ["PRODUCT", "COUPON_CODE"].includes(promotionProductPayout.payoutType.name)
      );
    },
    showPayoutAmountField(promotionProductPayout) {
      return (
        promotionProductPayout &&
        promotionProductPayout.payoutType &&
        !["PRODUCT", "COUPON_CODE"].includes(promotionProductPayout.payoutType.name)
      );
    },
    showThresholdField(promotionProductPayout) {
      return (
        promotionProductPayout &&
        promotionProductPayout.payoutProductMethodType &&
        promotionProductPayout.payoutProductMethodType.name != "EACH" &&
        promotionProductPayout.payoutType &&
        ["PRODUCT", "COUPON_CODE"].includes(promotionProductPayout.payoutType.name)
      );
    },

    hasCustomField(index) {
      if (!this.selectedProgram.claimProductCustomFields || this.selectedProgram.claimProductCustomFields.length == 0) {
        return false;
      }

      return this.selectedProgram.claimProductCustomFields.length >= index - 1;
    },

    byRule(item) {
      return (
        this.isRuleEngineBased &&
        item.promotionProductPayouts &&
        item.promotionProductPayouts.some(payout => {
          const hasRequiredFields =
            item.ruleCondition &&
            item.ruleCondition.spelExpression &&
            item.ruleActions &&
            item.ruleActions[0] &&
            item.ruleActions[0].context &&
            item.ruleActions[0].context.expression;

          return payout.hasRule || (payout.ruleGroup && payout.ruleGroup.id) || hasRequiredFields;
        })
      );
    }
  }
};
</script>
